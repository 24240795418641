import React from "react";
import "./nav.css";
import {AiOutlineHome} from "react-icons/ai"
import {AiOutlineUser} from "react-icons/ai"

import {AiOutlineProject} from "react-icons/ai"
import {AiOutlineContacts} from "react-icons/ai"
import {useState} from 'react'


const Nav = () => {
  const[activeNav, setActiveNav]= useState('#')
  return (
  <nav>
<a href="#"onClick={()=> setActiveNav('#')} className={activeNav === "#" ? 'active': ''}><AiOutlineHome/></a>
<a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === "#about" ? 'active': ''}><AiOutlineUser/></a>

<a href="#portfolio" onClick={()=> setActiveNav('#portfolio')} className={activeNav === "#portfolio" ? 'active': ''}><AiOutlineProject/></a>
<a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === "#contact" ? 'active': ''}><AiOutlineContacts/></a>
  </nav>
  )
}

export default Nav;
